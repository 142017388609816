module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.attsavings.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-MZZK8M","brandTokens":["ATT","DTV","DTVB","ATTB"],"mapiBrandToken":"ATT","siteName":"attsavings","alternateName":"attsavings.com","siteURL":"https://www.attsavings.com","defaultTitleTemplate":"","defaultPhone":"8338151260","phoneSymbol":"-","defaultPromoCode":"12787","smartyStreetsWebsiteKey":"17515620751423239","addressQualificationCode":"att-saraplus","convertProjectID":"10046058","vwoProjectID":"894940","cobra":{"sitePath":"attsavings.com","buttonLink":"https://www.att.com/plans/wireless/?source=EPDXATFIB00AS100L","buttonText":"View Plans","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"attsavings","short_name":"attsavings","start_url":"/","background_color":"#0057B8","theme_color":"#0057B8","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b25bed15fad3f90cec4667f0746a57f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
